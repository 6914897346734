import React, { Component } from 'react'
import Header from './Components/Header'
import SubHeader from './Components/SubHeader'
import Home from './Components/Home'
import Details from './Components/Details'
import Registry from './Components/Registry'
import Footer from './Components/Footer'
import Background from './Components/Background'
import Gallery from './Components/Gallery'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import firebase from 'firebase/app'
import 'firebase/analytics'
import firebaseConfig from './config/firebase'


class App extends Component {
  constructor(props) {
    super(props)
    this.changePageState = this.changePageState.bind(this)

    let firebaseApp = null

    if (firebaseApp === null) {
        // Initialize Firebase
        firebaseApp = firebase.initializeApp(firebaseConfig)
        firebase.analytics()
    }
    this.state = {
      page: 'home'
    }
  }

  componentWillMount() {
    let urlArray = window.location.pathname.split('/')[1];
    if (urlArray === "") {
      return this.setState({ page: 'home' });
    }
    return this.setState({ page: urlArray });
  }

  changePageState(e) {
    console.log(e);
    return this.setState({ page: e })
  }

  render() {
    return (
      <div className="wrapper">
        <Router>
          <Header changePageState={this.changePageState} page={this.state.page}/>
          <SubHeader page={this.state.page} />
          <div className={`mainContainer ${this.state.page}`}>
            <Route exact={true} path="/" component={() => <Home changePageState={this.changePageState} page={this.state.page} />}/>
            <Route exact={true} path="/details" component={() => <Details changePageState={this.changePageState} page={this.state.page}/>} />
            <Route exact={true} path="/registry" component={() => <Registry changePageState={this.changePageState} page={this.state.page} />} />
            <Route exact={true} path="/gallery" component={() => <Gallery changePageState={this.changePageState} page={this.state.page} />} />
          </div>
        </Router>
        <Footer />
        <Background />
      </div>
    )
  }
}

export default App
