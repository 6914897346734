import React, { Component } from 'react';

class Background extends Component {
  render() {
    return (
      <div>
        <img className="branchOne" src={require(`../images/branchweb.png`)} alt="" />
        <img className="branchTwo" src={require(`../images/branchweb.png`)} alt="" />
      </div>
    )
  }
}

export default Background
