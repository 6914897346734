const firebaseConfig = {
    apiKey: "AIzaSyBiQ238uXGdJziGe4H2YJ3VgTP3YP4NmKs",
    authDomain: "wedding-799a9.firebaseapp.com",
    databaseURL: "https://wedding-799a9.firebaseio.com",
    projectId: "wedding-799a9",
    storageBucket: "wedding-799a9.appspot.com",
    messagingSenderId: "79618574916",
    appId: "1:79618574916:web:b7517c132b81b20277ca11",
    measurementId: "G-CDWW79QPBG"
  };

export default firebaseConfig
