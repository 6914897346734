import React, { Component } from 'react'

class ImageLoader extends Component {
  constructor(props) {
    super(props)
    this.onLoad = this.onLoad.bind(this)
    this.state = {
      loaded: false
    };  
  }

  static defaultProps = {
    className: "",
    loadingClassName: "img-loading",
    loadedClassName: "img-loaded"
  };

  onLoad = () => {
    this.setState(() => ({ loaded: true }));
  }

  render() {
    let { className, loadedClassName, loadingClassName, src, alt } = this.props;
    
    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return (
      <div className="image-animation-container">
        <img 
          src={require(`../images/${src}.jpg`)}
          alt={alt}
          className={className} 
          onLoad={this.onLoad} />
      </div>
    )
  }
}

export default ImageLoader