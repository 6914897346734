import React, { Component } from 'react';
import ScriptTag from 'react-script-tag';

class Registry extends Component {
  render() {
    return (
      <div className={`${this.props.page}`}>
        <h1>Registry</h1>
        <p>If you feel inclined to give a gift, we'd <b>prefer cash or check donations</b> towards our very ambitous kitchen remodel. We are looking to upgrade all of our appliances and would most appreciate cash gifts towards these items! We are very excited to host many friends and family at our home for years to come. Maybe we'll finally have that big house warming party we keep talking about. However, we also have a small registry if you'd prefer to send a gift.</p>

        <h3>Mailing Address</h3>
        <p className="important">600 Peach Ct<br />
          Fairfield, CA<br/>
          94534</p>
        <a className="zola-registry-embed" href="https://www.zola.com/registry/josephandashlyn2021" data-registry-key="josephandashlyn2021">Our Zola Wedding Registry</a>
        <ScriptTag type="text/javascript" src="https://widget.zola.com/js/widget.js" />
      </div>
    )
  }
}

export default Registry;