import React, { Component } from 'react';
import firebase from 'firebase/app'
import 'firebase/storage'

class Gallery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gallery: [],
            fetchOptions: {maxResults: 50}
        }
        this.fetchImages = this.fetchImages.bind(this)
    }
    

    fetchImages() {
        var storage = firebase.storage()
        var ref = storage.ref()
        var listRef = ref.child('/photos')
        const pattern = new RegExp(/^video/)

        listRef.list(this.state.fetchOptions)
        .then((res) => {
            res.items.map(itemRef => {
                var gsReference = storage.ref(itemRef.location.path_)
                gsReference.getMetadata().then((metadata) => {
                    console.log(metadata)
                    if (metadata.contentType.match(pattern) === null) {
                        // file is not a video
                        gsReference.getDownloadURL().then(url => {
                            let gallery = [...this.state.gallery]
                            gallery.push(<div className="gallery-item"><img key={itemRef.location.path_} src={url} alt="wedding_photo"></img></div>)
                            this.setState({gallery})
                        })
                    }
                    // } else {
                    //     // return true;
                    //     // file is a video
                    //     // gsReference.getDownloadURL().then(url => {
                    //     //     let gallery = [...this.state.gallery]
                    //     //     gallery.push(<div className="gallery-item"><video height="100%" width="100%" key={itemRef.location.path_} alt="wedding_video" autoPlay="autoplay" loop="loop" muted playsInline controls><source src={url} type={metadata.contentType}/></video></div>)
                    //     //     this.setState({gallery})
                    //     // })
                    // }
                })
                

                
                return true;
            })
            this.setState({fetchOptions: {...this.state.fetchOptions, pageToken: res.nextPageToken !== undefined ? res.nextPageToken : -1}})
        }).catch((error) => {
            // Uh-oh, an error occurred!
        });
    }

    componentDidMount() {
        try {
            this.fetchImages()
        } catch (err) {
            console.log(err)
        }
            // gsReference.getDownloadURL()
            // .then(url => {
            //     console.log(url)
            // })
            // .catch(err => {
            //     switch (err.code) {
            //         case 'storage/object-not-found':
            //             // File doesn't exist
            //             break;
            //         case 'storage/unauthorized':
            //             // User doesn't have permission to access the object
            //             break;
            //         case 'storage/canceled':
            //             // User canceled the upload
            //             break;
            //         case 'storage/unknown':
            //             // Unknown error occurred, inspect the server response
            //             break;
            //     }
            // });
    }


    render() {
        return (
        <div className={`${this.props.page}`}>
            <h1>Gallery</h1>
            <p>Share your favorite memories from the night with us.</p>
            <div className="gallery-container">{this.state.gallery}</div>
            <div className="gallery-load-more"><button onClick={this.fetchImages} hidden={this.state.fetchOptions.pageToken === -1}>Load More</button></div>
        </div>
        )
    }
}

export default Gallery;
