import React, { Component } from 'react'
import { Link } from 'react-router-dom'
class Header extends Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = {
      menuOpen: true,
      width: window.innerWidth,
      mobile: true
    }
  }

  getDimensions = () => {
    this.setState({ width: window.innerWidth });
    this.setMenuType();
  }

  setMenuType = () => {
    if (this.state.width < 400) {
      this.setState({
        menuOpen: false,
        mobile: true
      })
      return
    }
    else {
      this.setState({
        menuOpen: true,
        mobile: false
      })
      return
    }
  }

  componentDidMount() {
    this.getDimensions();
    window.addEventListener('resize', this.getDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.getDimensions)
  }

  toggleMenu() {
    return this.setState({ menuOpen: !this.state.menuOpen })
  }
  render() {

    let nav = () => {
      return (
        <div className="nav">
          <Link
            to="/"
            className={`${this.props.page === 'home' ? 'active' : ''}`}
            onClick={() => this.props.changePageState('home')}>
            Welcome</Link>
          <Link
            to="/details"
            className={`${this.props.page === 'details' ? 'active' : ''}`}
            onClick={() => this.props.changePageState('details')}>
            Wedding Details</Link>
          <Link
            to="/registry"
            className={`${this.props.page === 'registry' ? 'active': ''}`}
            onClick={() => this.props.changePageState('registry')}>
            Registry</Link>
            <Link
            to="/gallery"
            className={`${this.props.page === 'gallery' ? 'active': ''}`}
            onClick={() => this.props.changePageState('gallery')}>
            Gallery</Link>
        </div>
      )
    }

    let mobileHeader = () => {
      return (
        <div className="navContainer">
          {this.props.page === 'home' && <h2>Welcome</h2>}
          {this.props.page === 'details' && <h2>Wedding Details</h2>}
          {this.props.page === 'registry' && <h2>Registry</h2>}
          {this.props.page === 'gallery' && <h2>Gallery</h2>}
          <div className="showMore">
            <button onClick={this.toggleMenu}>
              <img src={require(`../images/downChevron.svg`)} alt="Ashlyn and Joseph's Wedding Website" />
            </button>
          </div>
        </div>
      )
    }
    return (
      <header>
        <img className="logo" src={require(`../images/name.png`)} alt="Ashlyn and Joseph's Wedding Website" />
        <p>
          <span>September 11th, 2021</span>
          <span>Mission Ranch</span>
        </p>
        {this.state.mobile && mobileHeader()}
        {this.state.menuOpen && nav()}
      </header>
    )
  }
}

export default Header
