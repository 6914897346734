import React from 'react'

const Footer = () =>{
    return (
      <footer>
        <div className="container">
          <p>Created with <span role="img" aria-label="love">❤️</span> by Ashlyn &amp; Joseph</p>
        </div>
      </footer>
    )
}

export default Footer
