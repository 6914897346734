import React, { Component } from 'react'
import ImageLoader from './ImageLoader'
import LazyLoad from 'react-lazy-load'

class Details extends Component {
  render() {
    return (
        <div className={`${this.props.page}`}>
          <h1>Wedding Details</h1>

          <p className="important">September 11th, 2021 at 4:30pm<br />
          Mission Ranch<br />
          26270 Dolores St<br />
          Carmel-By-The-Sea, CA 93923</p>

          <h2>Basic Information</h2>
          <h3>About Mission Ranch</h3>
          <p>The historic Mission Ranch sits on 22 acres with spectacular views of Point Lobos, Carmel River Beach and the Pacific Ocean. Once one of the first dairies in California, the rich history of this 1800’s ranch was preserved and restored by former Carmel Mayor, Clint Eastwood. A longtime Carmel resident, Clint Eastwood rescued this landmark resort from condo developers, and renovated the entire property to provide a unique resort experience. The Inn now consists of 31 hotel rooms located within ten buildings on the property. While dining at the <a href="https://www.missionranchcarmel.com/restaurant.htm" target="_blank" rel="noopener noreferrer">restaurant</a>, known for its American Cuisine and Piano Bar, you can watch the sheep graze in the meadow.</p>
          <LazyLoad  offsetTop={200} debounce={false} throttle={400}>
            <ImageLoader src="mission-ranch" alt="Mission Ranch meadow"/>
          </LazyLoad>
          <h3>COVID Safety</h3>
          <p>To keep our guests and vendors safe, we ask that everyone attending is vaccinated. If you are unable to get vaccinated due to health reasons, you may choose to self quaratine for 10 days and get a Covid test 3 days before. Proof of vaccination can either be submitted during your online RSVP submission or emailed to Joseph and Ashlyn personally. If you are unvaccinated, please email Joseph and Ashlyn your negative covid test results before Saturday, September 11th.</p>

          <h3>Welcome Happy Hour</h3>
          <p>We are having an informal happy hour at <a href="https://goo.gl/maps/4yviiXU9vdHpsuxD9" target="_blank" rel="noopener noreferrer">Sadie's</a> around 8pm. Come by and say hello!</p>
          
          <h3>Attire</h3>
          <p>We’re encouraging guests to dress semi-formal. The ceremony and cocktail hour will be outside so please bring something warm for potentially cool coastal weather. September weather in Carmel-By-The-Sea is around 70 degrees during the day and mid 50s at night. The ceremony will also be on a lawn, so for the ladies, consider wedges instead of heels.</p>

          <h3>Families</h3>
          <p>We are having an adult-only ceremony. However, we know how hard it is to find babysitters while traveling. We will have a babysitter on-site and will have crafts and pizza for the kids during the ceremony. Children are welcome to join anytime after at the reception. Please coordinate with Joseph and Ashlyn if your family is interested in the babysitting option.</p>

          <h3>Parking</h3>
          <p>Some parking is available at Mission Ranch. There is also lots of street parking near the elementary school just around the corner.</p>

          <h2>Travel Tips</h2>

          <h3>Hotels</h3>
          <p>While Mission Ranch has some rooms that are available onsite, downtown Carmel is very close with numerous hotel options. Staying near downtown Carmel will offer many shops and restaurants within walking distance from your hotel. Monterey is also approximately 20 minutes away and may have more affordable hotel offerings.</p>

          <h3>Flights</h3>
          <p>While Monterey (closest city to Carmel) has a local airport, San Jose International Airport will most likely offer more reasonable direct flights. San Jose Airport is approximately an hour and a half away from the venue without traffic. Renting a car from the airport will be a must!</p>

          <h2>Other Things To Do</h2>
          <ul>
            <li>Day trip to Big Sur for stunning hiking near the coast and through Redwoods.</li>
            <li>Rent bikes and ride in Monterey or along 17 mile drive.</li>
            <li>Visit the local sea life at Monterey Bay Aquarium.</li>
            <li>Spend a day on the beach at Carmel.</li>
          </ul>

          <h3>Something else on your mind?</h3>
          <p>Please don't hesitate to reach out to us if you have more questions. To get the fastest response, email <b>ashlyn.aiu@gmail.com</b></p>
        </div>
    )
  }
}

export default Details