import React, { Component } from 'react';
import ImageLoader from './ImageLoader';
import LazyLoad from 'react-lazy-load';

class Home extends Component {
  render() {
    return (
      <div className={`${this.props.page}`}>
        <h1>Wedding</h1>
        <p>Joseph and Ashlyn are elated to have you join them at their intimate wedding at Mission Ranch in Carmel-By-The-Sea. The venue has been a favorite for the two when wanting to grab a drink in a cozy bar while visiting the area.</p>

        <p>Though its probably not a secret to most, Joseph and Ashlyn met when working at the same company 6 years ago. What started off as just teammates on a project quickly turned into a close friendship, and then a bit more. Though Joseph and Ashlyn no longer work together, they still find time to collaborate on “internet things”; like making this website from complete scratch.</p>

        <LazyLoad offsetTop={10} debounce={false} throttle={400}>
            <ImageLoader src="engagement" alt="Engagement at Waterdog Park in Belmont"/>
        </LazyLoad>
        <p>While Ashlyn was completely surprised at the timing of the proposal in April 2020 during Covid quarantine, Joseph had been getting hints from all sides that it might be time to pop the question. One morning during a work break, they went on a mountain bike ride (the couple’s true passion and obsession in life) where Joseph unsuccessfully tried to break Ashlyn’s stubborn habits to propose on a different segment of the trail. Very true to their life together, Joseph was quick on his feet, rerouted his plan, and proposed at the top of the ridge line at Waterdog Park. Obviously, Ashlyn said "yes."</p>

        <p>Within months of the proposal, the two had booked a wedding venue, purchased a home, and brought home a new addition to the family. A labordoodle named, Boba! It goes without saying that the two are more than prepared for what might lay ahead.</p>
        <LazyLoad offsetTop={10} debounce={false} throttle={400}>
            <ImageLoader src="boba" alt="Bringing home, Boba the Labradoodle."/>
        </LazyLoad>
      </div>
    )
  }
}

export default Home;