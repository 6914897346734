import React, { Component } from 'react';

class SubHeader extends Component {
  render() {
    return (
      <div className={`${this.props.page} SubHeader`}>
        <img className="hero" src={require(`../images/${this.props.page}-hero.jpg`)} alt="Ashlyn and Joseph at Carmel Beach" />
        <img className="title" src={require(`../images/${this.props.page}-title.svg`)} alt="Welcome to Ashlyn and Joseph's wedding site." />
        <h1>{this.props.page}</h1>
      </div>
    )
  }
}

export default SubHeader
